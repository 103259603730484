/* src/components/UserProfile.css */
.user-profile-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    word-wrap: break-word; /* 長い単語を折り返し表示 */
  }
  
  .user-profile-container h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  .user-profile-container p {
    font-size: 18px;
    color: #555;
    margin: 10px 0;
  }
  
  .user-profile-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .user-profile-container ul li {
    background-color: #f9f9f9;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
  }
  