/* src/components/ContentDetail.css */
.content-detail-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .content-detail-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .content-detail-container h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .content-detail-container p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
  }
  
/* src/components/ContentDetail.css */
.button-complete {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .button-complete:hover {
    background-color: white;
    color: black;
    border: 2px solid #007bff;
  }
  
  