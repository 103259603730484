/* src/components/Header.css */

.header {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  height: 60px; /* ヘッダーの高さを指定 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 軽いシャドウを追加 */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* コンテンツの最大幅を指定 */
  margin: 0 auto; /* 中央揃え */
}

.logo {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.navigation {
  display: flex;
  gap: 15px;
}

.navigation a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
}

.navigation a:hover {
  background-color: #444; /* ホバー時の背景色 */
  border-radius: 4px; /* ホバー時に角を丸くする */
}

.logout-button {
  background-color: #ff9800;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e68900;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

/* モバイル表示用のスタイル */
@media (max-width: 768px) {
  .navigation {
    display: none;
    flex-direction: column;
    gap: 0;
    background-color: #333;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    text-align: center;
  }

  .navigation.open {
    display: flex;
  }

  .navigation a {
    padding: 15px 0;
    border-bottom: 1px solid #444;
  }

  .menu-toggle {
    display: block;
  }
}
