/* src/components/Footer.css */

.footer {
  padding: 20px;
  background-color: #333333; /* フッター色：ダークグレー */
  color: #FFFFFF; /* テキスト色：白 */
  text-align: center;
}

.footer-links {
  margin-bottom: 10px;
}

.footer a {
  color: #FFFFFF; /* リンク色：白 */
  margin: 0 10px;
  text-decoration: none;
}

.footer .social-media a {
  margin: 0 10px;
}
