/* src/components/LandingPage.css */

.landing-page {
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .hero {
    background-color: #ff9800;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hero-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .hero-text {
    max-width: 600px;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    text-decoration: none;
  }
  
  .cta-button:hover {
    background-color: #555;
  }
  
  .features, .testimonials, .support {
    padding: 40px 20px;
    text-align: center;
  }
  
  .features h2, .testimonials h2, .support h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .feature-grid, .testimonial-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .feature, .testimonial {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
  }
  
  .feature-icon, .testimonial-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #ff9800;
  }
  
  .feature h3, .testimonial p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .faq {
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .faq h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .question {
    margin-bottom: 20px;
  }
  
  .question h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 40px auto 0;
    text-align: left;
  }
  
  .contact-form h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-form button {
    background-color: #ff9800;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #e68900;
  }
  
  /* スマホ表示用のスタイル */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .features h2, .testimonials h2, .support h2 {
      font-size: 1.5rem;
    }
  
    .feature, .testimonial {
      max-width: 100%;
    }
  }
  