/* src/components/Library.css */
.library-container {
    padding: 20px;
  }
  
  .content-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .content-card {
    width: calc(100% - 20px);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .content-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .content-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .content-details {
    text-align: center;
  }
  
  .content-details h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  @media (min-width: 768px) {
    .content-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (min-width: 992px) {
    .content-card {
      width: calc(33.33% - 20px);
    }
  }
  
  @media (min-width: 1200px) {
    .content-card {
      width: calc(25% - 20px);
    }
  }
  