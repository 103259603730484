/* src/components/AddContent.css */
.add-content-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-content-container h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  .add-content-container form {
    display: flex;
    flex-direction: column;
  }
  
  .add-content-container form div {
    margin-bottom: 15px;
  }
  
  .add-content-container form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .add-content-container form input, 
  .add-content-container form textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
  }
  
  .add-content-container form textarea {
    resize: vertical;
  }
  
  .add-content-container form button {
    padding: 10px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-content-container form button:hover {
    background-color: #0056b3;
  }
  