/* src/components/PrivacyPolicy.css */
.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .privacy-policy-container ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 5px;
  }
  