/* src/components/SearchBar.css */
.search-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    margin: 0 auto 20px;
  }
  
  .search-input, .search-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  @media (min-width: 768px) {
    .search-bar {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .search-input, .search-select {
      width: calc(25% - 10px);
    }
  }
  