/* src/components/Register.css */
.register-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.register-container:hover {
    transform: scale(1.02);
}

.register-container h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    color: #333;
}

.register-container form {
    display: flex;
    flex-direction: column;
}

.register-container form div {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.register-container form label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.register-container form input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.register-container form input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.register-container form button {
    padding: 10px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-container form button:hover {
    background-color: #0056b3;
}
