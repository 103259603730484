/* client/src/styles/global.css */
/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Base Styles */
  body {
    font-family: 'Arial, sans-serif';
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Layout */
  .container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Color Palette */
  :root {
    --primary-color: #4CAF50;
    --secondary-color: #FF5722;
    --background-color: #f4f4f4;
    --text-color: #333;
  }
  
  /* Utility Classes */
  .text-center {
    text-align: center;
  }
  
  .mt-1 {
    margin-top: 1rem;
  }
  
  .mb-1 {
    margin-bottom: 1rem;
  }
  